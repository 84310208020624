import React from "react"
import SalaryLoanRetail from "../../components/body/pages/salary-loan"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Helmet } from "react-helmet"

const SalaryLoanRetailPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/salary-loan/"}
      title="Salary Loans | Quick and easy Salary Loans with Kuda | Kuda"
      description="Get a salary loan from Kuda with low interest rates and flexible repayment options. Apply online now and access quick cash when you need it"
    />

    <SalaryLoanRetail />
  </Layout>
)

export default SalaryLoanRetailPage
